import React, { useState, useEffect } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import SEO from 'components/SEO'
import CssBaseline from 'components/CssBaseline'
import Header from 'components/Header'
import { isAuthorized } from 'services/auth'
import { FlexBox, TextContainer, Typography, Container, Spacer } from 'components/Core'
import LoginModal from 'components/LoginModal'
import Pagination from 'components/portfolio/PortfolioPagination'

const classMap = {
  1: 'twelve columns',
  2: 'six columns',
  3: 'four columns'
}

const PortfolioImg = styled(Img)`
  margin-bottom: 2rem;
`

const Root = styled.div`
  filter: ${({ blur }) => blur ? 'blur(2px)' : '' }; 
`

export default function PortfolioDetail ({ pageContext }) {
  const { node, imgMap, pagination } = pageContext
  const [open, setOpen] = useState(false)
  const [fetched, setFetched] = useState(false)

  useEffect(() => {
    const checkAuth = async () => {
      if (!await isAuthorized()) {
        setOpen(true)
      }
      setFetched(true)
    }
    
    if (node.auth && !open && !fetched) {
      checkAuth()
    }
  }, [node.auth, open, fetched])

  const renderJobImages = (images) => {
    return images.map((row, i) => {
      const numCols = row.length
      return (
        <div key={i}>
          { row.map((col) => (
            <PortfolioImg
              key={col}
              className={classMap[numCols]}
              fluid={imgMap[`img/portfolio/${node.name}/${col}`].childImageSharp.fluid}
              alt={col}
            />
          ))}
        </div>
      )
    })
  }

  const renderJobBanner = (job) => {
    return (
      <FlexBox>
        <TextContainer>
          <Typography variant="title1">{job.title}</Typography>
          <Typography variant="body1" font="secondary">{job.description}</Typography>
          <Spacer size="1" />
          <Typography variant="subtitle1">ROLE</Typography>
          <Typography variant="body1" font="secondary">{job.role}</Typography>
          <Spacer size="1" />
          <Typography variant="subtitle1">DELIVERABLES</Typography>
          <Typography variant="body1" font="secondary">{job.deliverables}</Typography>
          <Spacer size="2" />
        </TextContainer>
      </FlexBox>
    )
  }

  const renderJob = (job) => {
    return (
      <Container key={job.title}>
        { renderJobBanner(job) }
        { renderJobImages(job.images) }
      </Container>
    )
  }

  return (
    <CssBaseline grid>
      <SEO title={node.title} />
      <Root className="container" blur={open}>
        <Header backTo="/portfolio" />
        { node.jobs.map(j => renderJob(j)) }
        <Pagination pagination={pagination} />
        <Spacer size="4" />
      </Root>
      <LoginModal open={open} setOpen={setOpen} pagination={pagination} />
    </CssBaseline>
  )
}
