import React from 'react'
import styled from 'styled-components'
import monster from 'img/monster/1.svg'
import { Typography, CenterText, Spacer } from 'components/Core'
import { authorize } from 'services/auth'
import { pallete } from 'services/theme'
import Pagination from 'components/portfolio/PortfolioPagination'

const Modal = styled.div`
  z-index: 1010;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(600px - 8rem);
  max-width: 100%;
  background-color: white;
  font-family: BarlowSemiCondensed-Medium;
  padding: 2rem 4rem;
`

const Overlay = styled.div`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.3);
`

const MonsterImg = styled.img`
  height: 6rem;
  width: 6rem;
`

const PasswordInput = styled.input`
  width: 100%; 
  height: 40px;
  padding: 6px 10px; /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: #fff;
  font-size: 1rem;
  font-family: BarlowSemiCondensed-Medium;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid ${pallete.gray.main};
  box-shadow: none;
  box-sizing: border-box; 
  appearance: none; 
  &:focus {
    border-bottom: 1px solid ${pallete.blue.main};
    outline: 0;
  }
`

const SubmitButton = styled.button`
  width: 100%;
  height: 40px;
  display: inline-block;

  font-family: BarlowSemiCondensed-Medium;
  font-size: 18px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: .1rem;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  
  border: none;
  border-radius: 0;
  color: white;
  background-color: black; 
  cursor: pointer;
  box-sizing: border-box;
  &:hover, :focus {
    background-color: ${pallete.pink.main}; 
    outline: 0;
  }
`

const Form = styled.form`
  padding: 1rem;
`

const ErrorMessage = styled(Typography)`
  color: ${pallete.pink.main};
`

export default function LoginModal ({ open, setOpen, pagination }) {
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState(false)

  if (!open) {
    return null
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (await authorize(password)) {
      setOpen(false)
      return
    }
    setError(true)
  }

  return (
    <React.Fragment>
      <Modal>
        <Spacer size="1" />
        <CenterText>
          <MonsterImg src={monster} alt="monster" />
        </CenterText>
        <Spacer size="1" />
        <Form onSubmit={handleSubmit}>
          <Typography variant="subtitle1" font="secondary">
            This content is password protected.
            If you do not have the password, contact me at boo@heyashley.com.
          </Typography>
          <Spacer size="1" />
          <PasswordInput
            value={password}
            onChange={handlePasswordChange}
            type="password"
            placeholder="PASSWORD"
          />
          <Spacer size="1" />
          <SubmitButton
            type="submit"
          >
            {'SUBMIT'}
          </SubmitButton>
          <Spacer size="1" />
          { error &&
            <ErrorMessage variant="subtitle2" font="secondary">Invalid password</ErrorMessage>
          }
          <Spacer size="3" />
          <Pagination pagination={pagination} />
        </Form>
      </Modal>
      <Overlay />
    </React.Fragment>
  )
}
