import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { typography, pallete } from 'services/theme'

const Pagination = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

const PaginationLink = styled(Link)`
  font-size: ${typography[2]};
  text-decoration: none;
  outline: none;
  color: black;
  &:hover {
    color: ${pallete.pink.main};
  }
`

export default function PortfolioPagination ({ pagination }) {
  return (
    <Pagination>
      <PaginationLink to={`/portfolio/${pagination.prev}`}>‹ PREVIOUS GOODNESS</PaginationLink>
      <PaginationLink to={`/portfolio/${pagination.next}`}>NEXT PROJECT ›</PaginationLink>
    </Pagination>
  )
}